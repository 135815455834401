<template>
    <div class="question-item">
        <h2 :required="required" :style="{ color: pageInfo.style.questionColor }">{{title}}</h2>
        <div>
            <Input :rows="3" :maxlength="maxLength" type="textarea" :show-word-limit="maxLength > 0" long />
        </div>
    </div>
</template>
<script>
export default {
    name: "MultipleLineText",
    props: ["title",  "required", "maxLength", "pageInfo"]
}
</script>